<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtreleme</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-form-group label="Gün Sayısı" label-for="name">
              <b-form-input
                name="firstName"
                id="name"
                type="text"
                v-model="dayCount"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-button
              block
              variant="success"
              @click="getData()"
              style="margin-top: 20px; width: 100%"
              >FİLTRELE</b-button
            >
          </b-col>
        </b-row>
        <b-overlay :show="loading" no-wrap> </b-overlay>
      </b-card-body>
    </b-card>
    <app-collapse type="margin">
      <app-collapse-item title="Giren Öğrenciler">
        <vue-good-table
          theme="polar-bear"
          :rows="users.possitiveUsers"
          :columns="fields"
          :line-numbers="true"
          :sort-options="{
            enabled: false,
          }"
          >>
          <template slot="table-row" slot-scope="props">
            <b-button
              v-if="props.column.field === 'username'"
              block
              variant="flat-primary"
              class="font-weight-bolder mb-12"
              @click="openUserWatch(props.row.id)"
              >{{ props.row.firstName + " " + props.row.lastName }}</b-button
            >
          </template>
        </vue-good-table>
      </app-collapse-item>
      <app-collapse-item title="Girmeyen Öğrenciler">
        <vue-good-table
          theme="polar-bear"
          :rows="users.negativeUsers"
          :columns="fields"
          :line-numbers="true"
          :sort-options="{
            enabled: false,
          }"
          >>
          <template slot="table-row" slot-scope="props">
            <b-button
              v-if="props.column.field === 'username'"
              block
              variant="flat-primary"
              class="font-weight-bolder mb-12"
              @click="openUserWatch(props.row.id)"
              >{{ props.row.firstName + " " + props.row.lastName }}</b-button
            >
          </template>
        </vue-good-table>
      </app-collapse-item>
    </app-collapse>
    <b-card v-if="users" no-body class="card-company-table"> </b-card>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BSidebar,
  BTooltip,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BMediaBody,
  BMedia,
  BMediaAside,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  BProgress,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    AppCollapse,
    AppCollapseItem,
    ToastificationContent,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      fields: [
        {
          field: "username",
          label: "Öğrenci"
        },
        {
          field: "gsmNumber",
          label: "Telefon Numarası",
          filterOptions: {
            enabled: true,
            placeholder: "Telefon Numarasına Göre Ara",
          },
        },
        {
          field: "email",
          label: "Mail Adresi",
          filterOptions: {
            enabled: true,
            placeholder: "Mail Adresine Göre Ara",
          },
        },
        {
          field: "className",
          label: "Sınıfı",
          filterOptions: {
            enabled: true,
            placeholder: "Sınıfına Göre Ara",
          },
        },
        { field: "lastVisitDate", label: "Son İşlem Tarihi" },
        { field: "lastDayCount", label: "Son İşlemden Geçen Gün" },
      ],
      users: [],
      dayCount: 3,
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    openUserWatch(userId) {
      let routeData = this.$router.resolve({
        name: "watchingUser",
        params: { uid: userId },
      });
      window.open(
        routeData.href,
        "Öğrenci Hareketleri",
        "height=800,width=800"
      );
    },
    async getData() {
      this.loading = true;
      var response = await this.$http.post("Report/StudentTracing", {
        dayCount: this.dayCount,
        type: 0,
      });

      var data = response.data.data;
      this.users = data;
      console.log(data);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
